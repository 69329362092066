import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { TestimonialGrid, TestimonialCard } from '../../components/TestimonialGrid'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2, PageTitle3 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';

import HighPerformanceFeatures from '../images/deviceLineupFull.png'
import SfHalf from '../images/sfHalf.png'
import SetDisposition from '../images/setDisposition.png'
import TransamericaDialer from '../animations/transamericaDialer.json'
import ActionEvent from '../images/actionEvent.svg';

var lottie

export default class BetterDialer extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: TransamericaDialer,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Dialer</title>
        </Helmet>

        <WrapperBackground
          color={colors.trulyDark}
          background='headsetPerson3'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
              Sales Dialer
              </PageTitle2>
              <PageTitle as="p" color={colors.white} mb="1.5rem" center>
              Meet The Highest Rated Calling Solution For Outbound Sales Teams
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground center color={colors.trulyDark} background="managedByQ" gradientType="blueToGreen">
          <Section background={colors.trulyDark} flexDirection="column" maxWidth="100%">
            <Box>
                <PageTitle2 color={colors.white} mb="2rem" mt="2rem">
                Trusted By Category Defining Sales<br />& Customer Success Teams
              </PageTitle2>
            </Box>
            <TestimonialGrid>
              <TestimonialCard avatar="jackie" name="Jackie F" company="NewFront Insurance" quote="I love how you are able to listen to other colleagues calls while training. That live feature helped me significantly when I was training. I also like that you can see who is actively on a call."/>
              <TestimonialCard avatar="taylor" name="Taylor S" company="Cloud Kitchens" quote="Truly is great because it easily integrates with Salesforce to track my activity - both calls and texts. I also love the voicemail drop feature, as well as the mobile app that syncs flawlessly with my iPhone."/>
              <TestimonialCard avatar="billy" name="Billy B" company="Galvaninze" quote="I love how I can switch my number based on the purpose of my call and how easy Truly is to use. It's awesome I can listen to any of my colleagues phone calls at any time. It was a tremendous learning tool for me in my new job. The voicemail drop function is a game changer."/>
            </TestimonialGrid>
          </Section>
        </WrapperBackground>

        <WrapperSolid color='#292929'>
          <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
            <Box width={[1/2, 1/2]} mb={['16pt', '0pt']}>
              <CenteredImg src={SfHalf} alt="Salesforce"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
              THE FEATURES YOU NEED ON THE PLATFORMS YOU USE
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem">
              Truly offers the broadest coverage of <u>channels</u> (voice, meetings, text, email), <u>devices</u> (desktop, web, mobile) and  <u>integrations</u> (CRM, Helpdesk, Sales Tools, etc). 
              <br/><br/>
              Features include click-to-call, dispositions, CRM logging, voicemail drop, call monitoring, call recording, transcription and more.
              <br/><br/>
              This means reps can work in whatever way makes them more productive, while also benefitting from advanced data capture and analytics.
              <br/><br/>

              </Large>

            </Box>
          </Section>
        </WrapperSolid>

        <WrapperSolid
          color={colors.white}
        >
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem">
              INTEGRATED CONVERSATION INTELLIGENCE & AUTOMATION
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                Truly's integrated Conversation Intelligence automatically records, transcribes and analyzes your reps sales calls, making all of their conversation data searchable and reportable in Salesforce.
                <br/><br/>
               Using our Revenue Process Automation platform, you can then use this data to automate most of your sales coaching and data entry workflows, whether it's validating script compliance, updating dispositions or creating follow up tasks.
              </Large>
            </Box>
            <Box width={[1/2, 1/2]} p="32pt 0 32pt 32pt">
              <CenteredImg src={ActionEvent} alt="Set Disposition"/>
            </Box>


          </Section>
        </WrapperSolid>

        <WrapperSolid color='#292929'>
          <Section flexDirection="column">
            <Box width="1/2" pb="2rem">
                <CenteredImg src={HighPerformanceFeatures} alt="High Performance Features"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
              CENTRALIZED REPORTING IN SALESFORCE
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem" center>
                No more spreadsheets.  No more waiting.  No more logging into multiple platforms.
                <br/><br/>
                Regardless of what your sales stack looks like, our Salesforce package automatically replicates all of your key sales reporting in Salesforce, such as cadency analytics, opportunity analytics and forecast analytics.<br/><br/>  
                This means you can keep everyone working in CRM without worrying about being locked into any one sales tool.
              </Large>
            </Box>
          </Section>
        </WrapperSolid>
      </Default>
    )
  }
}

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`
